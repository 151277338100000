<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 30" fill="none">
        <path fill="currentColor"
            d="M28.308 5.17H12.214a.693.693 0 0 1-.691-.697c0-.385.309-.696.691-.696h16.094c.383 0 .692.311.692.696a.693.693 0 0 1-.692.697ZM4.71 5.17H.692A.693.693 0 0 1 0 4.473c0-.385.309-.696.692-.696H4.71c.382 0 .691.311.691.696 0 .386-.309.697-.691.697Z"
        />
        <path fill="currentColor"
            d="M8.47 8.943c-2.455 0-4.448-2.005-4.448-4.469C4.022 2.01 6.015 0 8.47 0c2.454 0 4.442 2.005 4.442 4.474 0 2.464-1.993 4.47-4.442 4.47Zm0-7.55c-1.69 0-3.064 1.382-3.064 3.081A3.073 3.073 0 0 0 8.47 7.551a3.069 3.069 0 0 0 3.058-3.077c0-1.699-1.374-3.082-3.058-3.082ZM28.308 15.696h-5.946A.693.693 0 0 1 21.67 15c0-.385.31-.696.692-.696h5.946c.383 0 .692.31.692.696 0 .385-.31.696-.692.696ZM14.857 15.696H.693A.693.693 0 0 1 0 15c0-.385.309-.696.692-.696h14.165c.383 0 .692.31.692.696 0 .385-.309.696-.691.696Z"
        />
        <path fill="currentColor"
            d="M18.617 19.469c-2.454 0-4.447-2.005-4.447-4.47 0-2.464 1.993-4.474 4.447-4.474 2.453 0 4.442 2.005 4.442 4.474 0 2.465-1.998 4.47-4.442 4.47Zm0-7.551c-1.689 0-3.063 1.383-3.063 3.081a3.073 3.073 0 0 0 3.063 3.077A3.069 3.069 0 0 0 21.675 15c0-1.698-1.375-3.081-3.058-3.081ZM28.308 26.222H14.059a.693.693 0 0 1-.692-.696c0-.385.31-.696.692-.696h14.249c.383 0 .692.311.692.696a.693.693 0 0 1-.692.696ZM6.555 26.221H.692A.693.693 0 0 1 0 25.525c0-.385.309-.696.692-.696h5.863c.382 0 .692.311.692.696 0 .386-.31.696-.692.696Z"
        />
        <path fill="currentColor"
            d="M10.314 30c-2.454 0-4.447-2.01-4.447-4.474 0-2.464 1.993-4.47 4.447-4.47 2.454 0 4.442 2.006 4.442 4.47 0 2.469-1.993 4.474-4.442 4.474Zm0-7.551a3.073 3.073 0 0 0-3.063 3.077c0 1.699 1.375 3.082 3.063 3.082 1.688 0 3.058-1.383 3.058-3.082 0-1.699-1.374-3.077-3.058-3.077Z"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
